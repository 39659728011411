





































































































































































































































import { defineComponent, computed, onMounted, onUnmounted, useContext, ref } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import urlJoin from 'url-join';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import AppOptimizedImage from '~/components/partials/App/AppOptimizedImage.vue';
import AppRectButton from '~/components/partials/App/AppRectButton.vue';
import AppSubmitButton from '~/components/partials/App/AppSubmitButton.vue';
import GlobalSNS from '~/components/partials/Global/GlobalSNS.vue';
import { useSilverEggApi } from '~/composables/silverEgg/useSilverEggApi';
import { useFirstPurchasePromotion } from '~/composables/useFirstPurchasePromotion';
import { useStore } from '~/composables/useStore';
import { useWOVNio } from '~/composables/wovn/useWOVNio';
import { ARCHIVE_GIFT, PRODUCT_CATEGORY_ID, PRODUCT_COLOR_ID, REQUEST_NUM_HISTORY_MENU } from '~/constants';
import { isInsidePeriod, nonNullable, url } from '~/utils';
import { sendNavigationEvent } from '~/utils/ga';
import priceFormatter from '~/utils/priceFormatter';
import { RecommendItem } from '~/types/common/silverEgg';
import { BooleanLike, DisplayLang } from '~/enums';

export default defineComponent({
  components: {
    AppIcon,
    AppLink,
    AppOptimizedImage,
    AppRectButton,
    AppSubmitButton,
    GlobalSNS,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const { i18n } = useContext();
    const { currentLang, targetDisplayLang, updateCurrentLang } = useWOVNio();
    const { hasPurchaseHistory } = useFirstPurchasePromotion();
    const { fetchHistoryItems, clickNotify } = useSilverEggApi();
    const authenticated = computed((): boolean => store.state.auth.authenticated);
    const profile = ref(store.state.user.info.profile);
    const pointBalance = ref(store.state.modules.mypage.point.pointBalance);
    const silverEggHistoryItems = ref<RecommendItem[]>();
    const historyRqid = ref<string>('');
    const historySpec = ref<string>('');

    // ボジョレー・ヌーヴォーの表示期間：8/1 9:00 〜 12/15 23:59
    const beaujolaisFrom = dayjs().tz('Asia/Tokyo').month(7).date(1).hour(9).minute(0).second(0).format();
    const beaujolaisTo = dayjs().tz('Asia/Tokyo').month(11).date(15).hour(23).minute(59).second(59).format();

    const loggedinMenuItems = [
      {
        name: i18n.t('Common.drawerMenu.userLinks.mypage').toString(),
        link: url('MYPAGE'),
        icon: 'Mypage',
      },
      {
        name: i18n.t('Common.drawerMenu.userLinks.orderHistory').toString(),
        link: url('MYPAGE_HISTORY_LIST'),
        icon: 'MypageHistory',
      },
      {
        name: i18n.t('Common.drawerMenu.userLinks.myWine').toString(),
        link: url('MYWINE'),
        icon: 'MyWine',
      },
      {
        name: i18n.t('Common.drawerMenu.userLinks.favorite').toString(),
        link: url('FAVORITE_LIST'),
        icon: 'ActionFavorite',
      },
    ];

    const COLOR_MENU = [
      {
        name: i18n.t('Common.drawerMenu.links.color.red').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_color=${PRODUCT_COLOR_ID.RED}`, `&_qty=${BooleanLike.TRUE}`),
        img: require('~/assets/images/red_wine.png'),
      },
      {
        name: i18n.t('Common.drawerMenu.links.color.white').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_color=${PRODUCT_COLOR_ID.WHITE}`, `&_qty=${BooleanLike.TRUE}`),
        img: require('~/assets/images/white_wine.png'),
      },
      {
        name: i18n.t('Common.drawerMenu.links.color.sparkling').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_color=${PRODUCT_COLOR_ID.SPARKLING}`, `&_qty=${BooleanLike.TRUE}`),
        img: require('~/assets/images/sparkling_wine.png'),
      },
      {
        name: i18n.t('Common.drawerMenu.links.color.rose').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_color=${PRODUCT_COLOR_ID.ROSE}`, `&_qty=${BooleanLike.TRUE}`),
        img: require('~/assets/images/rose_wine.png'),
      },
      {
        name: i18n.t('Common.drawerMenu.links.color.orange').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_color=${PRODUCT_COLOR_ID.ORANGE}`, `&_qty=${BooleanLike.TRUE}`),
        img: require('~/assets/images/orange_wine.png'),
      },
    ];

    /** 商品を探す（「色・タイプで探す」以外） */
    const SEARCH_MENU = [
      {
        name: i18n.t('Common.drawerMenu.links.wineSet').toString(),
        link: urlJoin(url('ITEM_LIST'), `?_ctg=${PRODUCT_CATEGORY_ID.WINE_SET}`, `&_qty=${BooleanLike.TRUE}`),
        icon: 'SearchWineSetIcon',
      },
      {
        name: i18n.t('Common.drawerMenu.links.wineGift').toString(),
        link: url('ARCHIVES_DETAIL', { permalink: ARCHIVE_GIFT }),
        icon: 'SearchGiftIcon',
      },
      {
        name: i18n.t('Common.drawerMenu.links.ranking').toString(),
        link: url('RANKING'),
        icon: 'SearchRankingIcon',
      },
      {
        name: i18n.t('Common.drawerMenu.links.producer').toString(),
        link: url('PRODUCER_LIST'),
        icon: 'SearchProducersIcon',
      },
      {
        name: i18n.t('Common.drawerMenu.links.staffReview').toString(),
        link: url('STAFF_REVIEW_LIST'),
        icon: 'SearchStaffReviewIcon',
      },
      {
        name: i18n.t('Common.drawerMenu.links.distributionCourse').toString(),
        link: url('ARCHIVES_DISTRIBUTION'),
        icon: 'MypageMonthly',
      },
      isInsidePeriod(beaujolaisFrom, beaujolaisTo)
        ? {
            name: i18n.t('Common.drawerMenu.links.beaujolais').toString(),
            link: url('ARCHIVES_NOUVEAU'),
            icon: 'Airplane',
          }
        : null,
      {
        name: i18n.t('Common.drawerMenu.links.search').toString(),
        link: '',
        icon: 'SearchInactive',
      },
    ].filter(nonNullable);

    /** その他（ショップ・ワインを楽しむコンテンツ・サービス・SNSリンク・言語切替） */
    const OTHER_MENUS = [
      {
        title: i18n.t('Common.drawerMenu.links.title.shop').toString(),
        // TODO: ショップトップ対応のときに追加
        // link: url('SHOP_TOP'),
        items: [
          {
            name: i18n.t('Common.drawerMenu.links.shop').toString(),
            link: url('SHOP_LIST'),
            description: null,
          },
          {
            name: i18n.t('Common.drawerMenu.links.blog').toString(),
            link: url('BLOG_LIST'),
            description: null,
          },
          {
            name: i18n.t('Common.drawerMenu.links.event').toString(),
            link: urlJoin(url('EVENT_LIST'), '?availableOnly=true'),
            description: null,
          },
        ],
      },
      {
        title: i18n.t('Common.drawerMenu.links.title.contents').toString(),
        items: [
          {
            name: i18n.t('Common.drawerMenu.links.article').toString(),
            link: url('ARTICLE_LIST'),
            description: 'エノテカオウンドメディア「おどるワイン」',
          },
          {
            name: i18n.t('Common.drawerMenu.links.college').toString(),
            link: url('COLLEGE_CATEGORY_LIST'),
            description: '講座とクイズでワインの基本が無料で学べる',
          },
        ],
      },
      {
        title: i18n.t('Common.drawerMenu.links.title.service').toString(),
        items: [
          {
            name: i18n.t('Common.drawerMenu.links.club').toString(),
            link: url('CLUB'),
            description: 'ワインラヴァー向けエノテカポイントサービス',
          },
          {
            name: i18n.t('Common.drawerMenu.links.rentalCellar').toString(),
            link: url('CELLAR'),
            description: 'お客様のワインを最適な環境でお預かりサービス',
          },
          {
            name: i18n.t('Common.drawerMenu.links.primeur').toString(),
            link: url('PRIMEUR'),
            description: '樽熟成中ワイン限定先行販売',
          },
        ],
      },
      {
        title: 'sns',
      },
      {
        items: [
          {
            name: i18n.t('Common.drawerMenu.links.news').toString(),
            link: url('NEWS_LIST'),
            description: '',
          },
          // TODO: とはページ対応のときに追加
          // {
          //   title: 'エノテカ・オンラインの楽しみ方',
          //   link: url('ENOTECA_LP'),
          // },
          {
            name: 'language',
          },
        ],
      },
      {
        items: [
          {
            name: i18n.t('Common.drawerMenu.logout').toString(),
            link: url('AUTH_LOGOUT'),
          },
        ],
      },
    ];

    const closeMenu = () => emit('close');

    const openSearchModal = () => {
      emit('openSearchModal');
    };

    /**
     * 商品検索メニューをクリックした際のGAイベント送信
     * @param eventCategory 遷移先のURL
     * @param eventLabel メニューの項目名
     */
    const sendGaEvent = (eventCategory: string, eventLabel: string) => {
      sendNavigationEvent('hamburger', true, eventCategory, eventLabel);
      closeMenu();
    };

    const clickHistoryItem = async (itemId: string) => {
      sendNavigationEvent(
        'hamburger',
        true,
        url('ITEM_DETAIL', { code: itemId }),
        i18n.t('Common.drawerMenu.history.title').toString()
      );
      await clickNotify({
        itemId,
        spec: historySpec.value,
        rqid: historyRqid.value,
      });
      closeMenu();
    };

    onMounted(async () => {
      // fix body element
      const scrollY = window.scrollY;
      document.body.classList.add('fixed', 'w-full');
      document.body.style.top = `${document.body.offsetTop - scrollY}px`;

      const historyRes = await fetchHistoryItems(REQUEST_NUM_HISTORY_MENU);
      silverEggHistoryItems.value = historyRes.items;
      historyRqid.value = historyRes.rqid;
      historySpec.value = historyRes.spec;
    });

    onUnmounted(() => {
      // unfix body element
      document.body.classList.remove('fixed', 'w-full');
      document.body.style.top = '';
      window.scrollTo(0, scrollY - document.body.offsetTop);
    });

    const handleLangChange = () => {
      closeMenu();
      updateCurrentLang(targetDisplayLang.value.code);
    };

    return {
      url,
      DisplayLang,
      currentLang,
      targetDisplayLang,
      handleLangChange,
      authenticated,
      closeMenu,
      sendGaEvent,
      loggedinMenuItems,
      profile,
      pointBalance,
      priceFormatter,
      hasPurchaseHistory,
      silverEggHistoryItems,
      clickHistoryItem,
      COLOR_MENU,
      SEARCH_MENU,
      OTHER_MENUS,
      openSearchModal,
    };
  },
});
