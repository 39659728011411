




















































































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import { SIMPLE_SEARCH_MENU_SP, SCENE_MENU, KEYWORD_MENU } from '~/constants';
import { url, convertRouteToUrl } from '~/utils';
import { sendNavigationEvent } from '~/utils/ga';
import { BooleanLike } from '~/enums';

export default defineComponent({
  components: {
    AppIcon,
    AppLink,
  },
  props: {},
  setup(props, { emit }) {
    // デフォルト：開く状態
    const isSceneMenuOpen = ref(true);
    const isKeywordMenuOpen = ref(true);

    const clickLink = (
      to:
        | string
        | {
            path: string;
            query?: Record<string, string | Array<string> | undefined>;
          },
      label: string
    ) => {
      sendNavigationEvent('searchTop', true, convertRouteToUrl(to), label);
      emit('clickLink');
    };

    return {
      url,
      SIMPLE_SEARCH_MENU_SP,
      isSceneMenuOpen,
      isKeywordMenuOpen,
      BooleanLike,
      SCENE_MENU,
      KEYWORD_MENU,
      clickLink,
    };
  },
});
