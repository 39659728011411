































































































import {
  defineComponent,
  ref,
  PropType,
  useMeta,
  useRoute,
  watch,
  computed,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import { castArray } from 'lodash-es';
import urlJoin from 'url-join';
import { MetaInfo } from 'vue-meta';
import { Location } from 'vue-router';
import ProductSearchModal from '~/components/pages/Product/Search/ProductSearchModal.vue';
import AppErrorView from '~/components/partials/App/AppErrorView.vue';
import AppFirstPurchasePromotion from '~/components/partials/App/AppFirstPurchasePromotion.vue';
import GlobalBreadcrumbs from '~/components/partials/Global/GlobalBreadcrumbs.vue';
import GlobalDrawerMenu from '~/components/partials/Global/GlobalDrawerMenu.vue';
import GlobalFooter from '~/components/partials/Global/GlobalFooter.vue';
import GlobalFooterMenu from '~/components/partials/Global/GlobalFooterMenu.vue';
import GlobalHeader from '~/components/partials/Global/GlobalHeader.vue';
import GlobalHeaderMenu from '~/components/partials/Global/GlobalHeaderMenu.vue';
import GlobalTopBanner from '~/components/partials/Global/GlobalTopBanner.vue';
import { useDefaultImage } from '~/composables/image/useDefaultImage';
import { OptimizedLayoutProps } from '~/composables/internals/useLayoutPropsProvider';
import { useJsonLdSchema } from '~/composables/jsonld/useJsonLdSchema';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useFirstPurchasePromotion } from '~/composables/useFirstPurchasePromotion';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { useStore } from '~/composables/useStore';
import { DEFAULT_TITLE, TEMPLATE_TITLE } from '~/constants';
import { url } from '~/utils';
import { APIError, PageError } from '~/utils/error';
import { sendNavigationEvent } from '~/utils/ga';
import { BreadcrumbsItem } from '~/types/ui';

export const BREADCRUMBS_ITEM_HOME: BreadcrumbsItem = {
  title: 'ワイン通販エノテカ',
  to: url('TOP'),
};

export default defineComponent({
  components: {
    GlobalHeader,
    GlobalDrawerMenu,
    GlobalHeaderMenu,
    GlobalTopBanner,
    GlobalBreadcrumbs,
    GlobalFooterMenu,
    GlobalFooter,
    AppErrorView,
    AppFirstPurchasePromotion,
    ProductSearchModal,
  },
  props: {
    layoutProps: {
      type: Object as PropType<OptimizedLayoutProps>,
      required: true,
    },
  },
  setup(props, { root: { $nuxt } }) {
    const { FRONTEND_ORIGIN } = useRuntimeConfig();
    const route = useRoute();
    const store = useStore();
    const { getJsonLdSchema } = useJsonLdSchema();
    const { ogpImagePath } = useDefaultImage();
    const { hasClosedPromo, closePromotion } = useFirstPurchasePromotion();
    const isDrawerMenuOpen = ref(false);
    const isProductSearchModalOpen = ref(false);
    const error = computed(() => castArray(props.layoutProps.fetchState).find(({ error }) => error)?.error);
    const topBanner = computed(() => store.state.modules.general.topBanner);
    const authenticated = computed(() => store.state.auth.authenticated);
    // 表示対象フラグ（商品一覧、生産者一覧、特集一覧）
    const isInsideFirstPurchasePromoRange = computed(() => {
      const { path } = route.value;
      return [url('ITEM_LIST'), url('PRODUCER_LIST'), url('ARCHIVES_LIST')].includes(path);
    });
    const isNotFound = computed(
      () => (APIError.isAPIError(error.value) || PageError.isPageError(error.value)) && error.value.statusCode === 404
    );
    const { isEnotecaAppWebView } = useEnotecaApp();
    const errorMessage = computed((): string => {
      if (isNotFound.value) {
        return 'お探しのページは見つかりませんでした。';
      }

      if (APIError.isAPIError(error.value)) {
        const [message] = error.value.messages;

        if (message) {
          return message;
        }
      }

      if (PageError.isPageError(error.value)) {
        const { message } = error.value.options;

        if (message) {
          return message;
        }
      }

      return '不明なエラーが発生しました。';
    });

    /**
     * ドロワーメニューの表示状態をトグルする
     */
    const toggleDrawerMenu = () => {
      if (!isDrawerMenuOpen.value) {
        sendNavigationEvent('hamburger', true);
      }

      isDrawerMenuOpen.value = !isDrawerMenuOpen.value;
    };

    const locationToUrlString = (location: Location) => {
      const query =
        location.query &&
        Object.entries(location.query)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
      const path = query ? `${location.path}?${query}` : location.path;

      return path ? urlJoin(FRONTEND_ORIGIN, path) : FRONTEND_ORIGIN;
    };

    const isRedirectPageFromOutSide = computed(() => {
      const { path } = route.value;

      return path === url('ORDER_CALLBACK_AMAZON') || path === url('ORDER_CALLBACK_RAKUTEN_PAY');
    });

    useMeta(() => {
      const { head, og, fb, twitter, jsonld } = props.layoutProps;
      const title = head.title || '';
      const ogpTitle = () => {
        /**
         * 後部のテンプレートに「| エノテカ - ワイン通販」を使用したくない場合、
         * titleTemplateをnullに設定することで、テンプレートを使用しないようにする(titleをそのまま返す)
         * 例：読み物一覧、読み物詳細
         */
        if (title && head.titleTemplate === null) {
          return title;
        }
        // titleTemplateと同じ処理を行う
        if (title) {
          return `${title}${TEMPLATE_TITLE}`;
        }
        if (og.title) {
          return og.title;
        }
        return DEFAULT_TITLE;
      };
      const meta: MetaInfo['meta'] = [
        { name: 'description', content: head.description },
        { name: 'keywords', content: head.keywords.toString() },
        {
          property: 'og:title',
          content: ogpTitle(),
        },
        { property: 'og:description', content: og.description === null ? head.description : og.description },
        { property: 'og:type', content: og.type },
        { property: 'og:url', content: og.url || urlJoin(FRONTEND_ORIGIN, route.value.fullPath) },
        {
          property: 'og:image',
          content: og.image === null ? ogpImagePath : og.image,
        },
        { property: 'fb:app_id', content: fb.app_id },
        { name: 'twitter:card', content: twitter.card },
        { name: 'twitter:site', content: twitter.site },
        { name: 'twitter:creator', content: twitter.creator },
      ];
      if (head.noindex) {
        meta.push({ name: 'robots', content: 'noindex' });
      }
      const link: MetaInfo['link'] = [];
      if (head.canonical) {
        const href = typeof head.canonical === 'string' ? head.canonical : locationToUrlString(head.canonical);

        link.push({
          rel: 'canonical',
          href: new URL(href, FRONTEND_ORIGIN).toString(),
        });
      }

      return {
        title,
        titleTemplate: head.titleTemplate === null ? '%s' : head.titleTemplate,
        meta,
        link,
        script: getJsonLdSchema(jsonld, props.layoutProps.breadcrumbs).map(({ type, schema }) => ({
          vmid: `ld+json__${type}`,
          type: 'application/ld+json',
          // JsonValue にキャストできなかったので as any した
          json: schema as any,
        })),
      };
    });

    if (process.server) {
      watch(
        error,
        (error) => {
          if (!error) {
            return;
          }

          const statusCode = APIError.isAPIError(error) || PageError.isPageError(error) ? error.statusCode : 500;

          $nuxt.context.res.statusCode = statusCode;
        },
        { immediate: true }
      );
    }

    const helloBarData = computed(() => {
      if (props.layoutProps.layout.helloBar === 'never') {
        return;
      }
      return store.state.modules.general.helloBar;
    });

    /**
     * アプリ以外の場合、CMPのポップアップを表示させる
     * onetrust-consent-sdkが挿入されるタイミングはvueで検知できないため、グローバルで打ち消しのstyleをあてています
     */
    onBeforeMount(() => {
      if (!isEnotecaAppWebView.value) {
        // HACK:型がHTMLCollectionのため、スプレッド演算子で展開することによって、配列として処理できるようにする
        const styleSheets = [...document.styleSheets];
        /**
         * wovnのウィジェット用cssが差し込まれる可能性があり、
         * 外部のものでない、hrefがnullのstyleSheetを指定する
         */
        const styleSheet = styleSheets.find((sheet) => !sheet.href);
        if (styleSheet) {
          styleSheet.insertRule('#onetrust-consent-sdk { display: block !important; }', 0);
        }
      }
    });

    return {
      isDrawerMenuOpen,
      isProductSearchModalOpen,
      toggleDrawerMenu,
      topBanner,
      error,
      isNotFound,
      errorMessage,
      helloBarData,
      isRedirectPageFromOutSide,
      sendNavigationEvent,
      hasClosedPromo,
      closePromotion,
      authenticated,
      isInsideFirstPurchasePromoRange,
    };
  },
  head: {},
});
