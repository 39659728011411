




































































import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import SearchSuggestion from '~/components/partials/Search/SearchSuggestion.vue';
import { useComponentId } from '~/composables/useComponentId';
import { url } from '~/utils';
import { sendNavigationEvent } from '~/utils/ga';

export default defineComponent({
  components: {
    AppIcon,
    SearchSuggestion,
  },
  props: {
    placeholder: {
      type: String,
      default: 'キーワードで検索',
    },
    tabletSizePlaceholder: {
      type: String,
      default: '',
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    showPlaceholderAlways: {
      type: Boolean,
      default: false,
    },
    isSp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const inputValue = ref('');
    const isSuggestOpen = ref(false);
    const keywordInput = ref<HTMLDivElement>();
    const { getComponentId } = useComponentId();

    const clearValue = () => {
      inputValue.value = '';
    };

    const closeSuggestion = () => {
      isSuggestOpen.value = false;
    };

    const clickSuggestion = () => {
      clearValue();
      closeSuggestion();
      emit('closeModal');
    };

    const resetInput = () => {
      clearValue();
      closeSuggestion();
    };

    const focusInput = () => {
      keywordInput.value?.focus();
    };

    const applyFilter = async () => {
      if (!inputValue.value) {
        return;
      }
      sendNavigationEvent('search', false);
      await router.push({ path: url('ITEM_LIST'), query: { _word: inputValue.value } });
      resetInput();
      emit('closeModal');
    };

    const handleOutsideClick = () => {
      closeSuggestion();
    };

    return {
      inputValue,
      clearValue,
      resetInput,
      focusInput,
      keywordInput,
      isSuggestOpen,
      applyFilter,
      clickSuggestion,
      handleOutsideClick,
      url,
      getComponentId,
    };
  },
});
