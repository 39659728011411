











































































































































import { defineComponent, ref, computed, PropType, watch, useContext, useRoute } from '@nuxtjs/composition-api';
import { useWindowScroll, useMutationObserver } from '@vueuse/core';
import { sumBy } from 'lodash-es';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppImage from '~/components/partials/App/AppImage.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import GlobalHelloBar from '~/components/partials/Global/GlobalHelloBar.vue';
import SearchInputWithSuggestion from '~/components/partials/Search/SearchInputWithSuggestion.vue';
import { OptimizedLayoutProps } from '~/composables/internals/useLayoutPropsProvider';
import { useComponentLifecycle } from '~/composables/useComponentLifecycle';
import { useElementLayout } from '~/composables/useElementLayout';
import { useMatchMedia } from '~/composables/useMatchMedia';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { useScrollUpdate } from '~/composables/useScrollUpdate';
import { useStore } from '~/composables/useStore';
import { useWOVNio } from '~/composables/wovn/useWOVNio';
import { SMARTPHONE } from '~/constants';
import { url } from '~/utils';
import { sendNavigationEvent, gaEventType } from '~/utils/ga';
import { HelloBar } from '~/types/common';
import { CartType } from '~/enums';

const LANGUAGES = [
  {
    code: 'ja',
    name: '日本語',
  },
  {
    code: 'en',
    name: 'English',
  },
];

export default defineComponent({
  components: {
    GlobalHelloBar,
    SearchInputWithSuggestion,
    AppIcon,
    AppImage,
    AppLink,
  },
  props: {
    hasPrimeurNav: {
      type: Boolean,
      default: false,
    },
    hasCellarNav: {
      type: Boolean,
      default: false,
    },
    hasVintageNav: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String as PropType<OptimizedLayoutProps['layout']['header']>,
      required: true,
    },
    helloBarData: {
      type: Object as PropType<HelloBar>,
      default: undefined,
    },
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    clickHamburger: () => true,
    emitSeachClicked: () => true,
  },
  setup(props, { slots, emit }) {
    const store = useStore();
    const route = useRoute();
    const scrollPosition = useWindowScroll();
    const { app } = useContext();
    const { ENABLED_WINENOTE } = useRuntimeConfig();
    const headerRootRef = ref<HTMLDivElement>();
    const headerRef = ref<HTMLDivElement>();
    const helloBarRef = ref<HTMLDivElement>();
    const { isMounted } = useComponentLifecycle();
    const { currentLang, updateCurrentLang } = useWOVNio();
    const { isMobile } = useMatchMedia(); // SSR問題なし
    const isLangMenuOpen = ref(false);
    const authenticated = computed((): boolean => store.state.auth.authenticated);
    const cartItemQuantity = computed(() =>
      isMounted.value
        ? sumBy(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            store.state.modules.cart.carts.flatMap(({ cart_name, items }) =>
              cart_name === CartType.GENERAL ? items : []
            ),
            'quantity'
          )
        : 0
    );
    const hasAnyNavigation = computed((): boolean => !!slots.default?.length);
    const emitHamburgerClicked = () => emit('clickHamburger');
    const emitSearchClicked = () => emit('clickSearchIcon');

    const { isAtBottom } = useScrollUpdate();
    const headerRootRect = useElementLayout(headerRootRef);
    const headerRect = useElementLayout(headerRef);
    const helloBarRect = useElementLayout(helloBarRef);
    const headerHeight = computed(() => headerRect.value.offsetHeight + helloBarRect.value.offsetHeight);
    // Modal が表示されているなどで画面が固定されているか
    const hasFixed = ref(false);
    const shouldHeaderHide = ref(false);

    const rootStyle = computed(() => {
      if (props.layout === 'always-only-menu' || isMobile.value) {
        const headerMenuHeight = route.value.path === url('TOP') ? 52 : 0;
        let top;

        if (isAtBottom.value) {
          top = 0; // 画面最下部に到達したらヘッダーを表示させる
        } else {
          top = !hasFixed.value && shouldHeaderHide.value ? -headerHeight.value - headerMenuHeight : 0;
        }

        if (isMobile.value) {
          if (top < 0) {
            store.commit('internals/header/setIsHeaderVisible', false);
          } else {
            store.commit('internals/header/setIsHeaderVisible', true);
          }
        }

        return {
          top: `${top}px`,
        };
      }
    });

    if (process.client) {
      // body に .fixed があるかで画面が固定されているかチェック
      useMutationObserver(
        document.body,
        ([mutation]) => {
          if (!mutation || !(mutation.target instanceof HTMLElement)) {
            return;
          }

          hasFixed.value = mutation.target.classList.contains('fixed');
        },
        { attributes: true }
      );
    }

    const handleLangChange = (newLang: string) => {
      if (newLang === currentLang.value.code) return;
      isLangMenuOpen.value = false;
      updateCurrentLang(newLang);
    };

    const isSmartPhone = computed(() => {
      return app.$ua.deviceType() === SMARTPHONE;
    });

    const clickHeaderNavigationEvent = (key: keyof typeof gaEventType) => {
      sendNavigationEvent(key, isSmartPhone.value);
    };

    const clickLogo = () => {
      // ロゴをクリックしてTOPに遷移した場合は、スクロール位置を保持せず0にする
      store.commit('internals/keepScroll/setPageData', {
        path: url('TOP'),
        fullPath: url('TOP'),
        scrollY: 0,
      });
      clickHeaderNavigationEvent('enotecaOnlineLogo');
    };

    const toggleLanguage = () => {
      clickHeaderNavigationEvent('languages');
      isLangMenuOpen.value = !isLangMenuOpen.value;
    };

    watch(
      [scrollPosition.y, headerHeight] as const,
      ([scrollY, headerHeight], [previousScrollY]) => {
        shouldHeaderHide.value =
          (props.layout === 'always-only-menu' || isMobile.value) &&
          // 上向きにスクロールされているか
          previousScrollY != null &&
          previousScrollY < scrollY &&
          // スクロール用が隠す要素の高さよりも大きいか
          scrollY > headerHeight;
      },
      { immediate: true }
    );

    watch(
      headerHeight,
      (headerHeight) => {
        store.commit('internals/header/setHeaderHeight', headerHeight);
      },
      { immediate: true }
    );

    // Sticky などで画面上部に固定する際のオフセットを同期する
    watch(
      [headerRootRect, headerHeight, shouldHeaderHide] as const,
      ([{ offsetHeight: headerRootHeight }, headerHeight, shouldHeaderHide]) => {
        const headerOffsetTop = shouldHeaderHide ? headerRootHeight - headerHeight : headerRootHeight;

        store.commit('internals/header/setHeaderOffsetTop', headerOffsetTop);
      },
      { immediate: true }
    );

    return {
      LANGUAGES,
      currentLang,
      isLangMenuOpen,
      handleLangChange,
      url,
      hasAnyNavigation,
      headerRootRef,
      headerRef,
      helloBarRef,
      authenticated,
      cartItemQuantity,
      rootStyle,
      hasFixed,
      emitHamburgerClicked,
      emitSearchClicked,
      ENABLED_WINENOTE,
      clickHeaderNavigationEvent,
      clickLogo,
      sendNavigationEvent,
      toggleLanguage,
      gaEventType,
    };
  },
});
