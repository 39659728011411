import { ref, useContext } from '@nuxtjs/composition-api';
import { isEnotecaApp, isEnotecaAppVersionHigherThanReview } from '~/utils';
import { UserCertification } from '~/types/common/user';

const loginEnotecaApp = (
  type: 'login' | 'signup',
  userCertification: UserCertification | null | undefined,
  cardNo: string | undefined
): void => {
  if (!process.client) {
    return;
  }

  const action = type === 'login' ? 'login_completed' : 'signup_completed';

  const searchParams = new URLSearchParams();

  searchParams.append('id_token', userCertification?.user.id_token || '');
  searchParams.append('login_token', userCertification?.user.login_token || '');
  searchParams.append('cno', cardNo || '');

  location.href = `enoteca-app-webview://${action}?${searchParams.toString()}`;
};

export const useEnotecaApp = () => {
  const {
    app: { $ua },
  } = useContext();

  /** ENOTECA アプリのWebViewかどうか */
  const isEnotecaAppWebView = ref(isEnotecaApp($ua.original()));

  /** アプリのレビュー依頼ダイアログを出せるバージョンかどうか */
  const canUseAppReview = ref(isEnotecaAppVersionHigherThanReview($ua.original()));

  return {
    isEnotecaAppWebView,
    canUseAppReview,
    loginEnotecaApp,
  };
};
