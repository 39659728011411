import { useRoute } from '@nuxtjs/composition-api';
import { Thing, BreadcrumbList, BlogPosting, WebSite, Product, HowTo, FAQPage, WithContext, Offer } from 'schema-dts';
import urlJoin from 'url-join';
import { RawLocation } from 'vue-router';
import { JsonLdData } from '~/composables/internals/useLayoutPropsProvider';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { stringifyLocation, nonNullable } from '~/utils';
import { formatKeyValueArray, getSpecialPricePromotion } from '~/utils/modules/products';
import {
  JsonLdBlogPosting,
  JsonLdSearchAction,
  JsonLdProduct,
  JsonLdHowTo,
  JsonLdFAQPage,
} from '~/types/common/jsonld';
import { ProductShowECResponseSchema } from '~/types/modules/product';
import type { BreadcrumbsItem } from '~/types/ui';

const logoUrl = require('~/assets/images/enoteca-online.svg');

// @ts-expect-error
const getNeverError = (item: never) => new Error(`JSON-LD タイプ ${item.type} は定義されていません。`);

const getProductAvailability = (ecDetail: ProductShowECResponseSchema): Offer['availability'] => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const hasStock = ecDetail.product_variants.some(({ stock_count }) => stock_count > 0);

  if (hasStock) {
    return 'https://schema.org/InStock';
  }

  const isOnlineOnly = ecDetail.is_online_only;

  if (isOnlineOnly) {
    return 'https://schema.org/OnlineOnly';
  }

  const isLimitedStock = formatKeyValueArray(ecDetail.attributes)?.mark_limited_stock_flag;

  if (isLimitedStock) {
    return 'https://schema.org/LimitedAvailability';
  }

  const isPrimeur = ecDetail.type === 'プリムール';

  if (isPrimeur) {
    return 'https://schema.org/PreOrder';
  }

  return 'https://schema.org/OutOfStock';
};

type JsonLdSchema<Schema extends Thing> = {
  type: string;
  schema: WithContext<Schema>;
};

export const useJsonLdSchema = () => {
  const route = useRoute();
  const { FRONTEND_ORIGIN } = useRuntimeConfig();

  const resolvePageUrl = (location: RawLocation | undefined | null): string => {
    return stringifyLocation(location || route.value.fullPath, FRONTEND_ORIGIN, false);
  };

  /** パンくずのスキーマを生成 */
  const getBreadcrumbListSchema = (breadcrumbs: BreadcrumbsItem[]): JsonLdSchema<BreadcrumbList> => ({
    type: 'BreadcrumbList',
    schema: {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map(({ title, to }, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: title,
          item: resolvePageUrl(to),
        };
      }),
    },
  });

  const getBlogPostingSchema = ({ type, data }: JsonLdBlogPosting): JsonLdSchema<BlogPosting> => ({
    type,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': resolvePageUrl(data.url),
      },
      headline: data.title,
      image: data.images,
      datePublished: data.publishedAt,
      dateModified: data.updatedAt || data.publishedAt,
      author: {
        '@type': 'Person',
        name: data.authorName || 'エノテカ編集部',
        url: data.authorUrl ? resolvePageUrl(data.authorUrl) : '',
      },
      publisher: {
        '@type': 'Organization',
        name: 'エノテカオンライン',
        logo: {
          '@type': 'ImageObject',
          url: resolvePageUrl(logoUrl),
        },
      },
      description: data.description,
    },
  });

  const getSearchActionSchema = ({ type, data }: JsonLdSearchAction): JsonLdSchema<WebSite> => ({
    type,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: urlJoin(FRONTEND_ORIGIN, route.value.fullPath),
      potentialAction: {
        '@type': 'SearchAction',
        target: urlJoin(resolvePageUrl(data.url), `?${data.keywordQuery}={search_term_string}`),
        // @ts-expect-error
        'query-input': 'required name=search_term_string',
      },
    },
  });

  const getProductSchema = ({ type, data }: JsonLdProduct): JsonLdSchema<Product> | undefined => {
    // 頒布会の場合は独自の処理
    if ('distributionDetail' in data) {
      const { distributionDetail } = data;

      if (!distributionDetail) {
        return;
      }

      const { name, main_image_url, catchcopy, price_settings, acceptable_count } = distributionDetail;

      return {
        type,
        schema: {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name,
          image: main_image_url ? [main_image_url] : [],
          description: catchcopy,
          offers: {
            '@type': 'Offer',
            url: resolvePageUrl(null),
            priceCurrency: 'JPY',
            price: price_settings.price_including_tax,
            availability: acceptable_count ?? Infinity > 0 ? 'https://schema.org/InStock' : undefined,
          },
        },
      };
    }

    if (!('ecDetail' in data)) {
      return;
    }

    const { ecDetail, comments } = data;

    if (!ecDetail) {
      return;
    }

    const { product_variants, best_priced_variant_id, code, master_settings } = ecDetail;
    const bestPricedVariant = product_variants.find(({ id }) => id === best_priced_variant_id);
    const specialPrice = getSpecialPricePromotion(bestPricedVariant?.price_settings)?.price_including_tax;
    /**
     * 送料無料かどうか
     * * 子商品の中に一つでも送料無料があればtrue
     */
    const isFreeShipping = product_variants.some((variant) => variant.price_settings.is_free_shipping);
    const DEFAULT_POSTAGE = 726;

    return {
      type,
      schema: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: ecDetail.langs.find(({ id }) => id === 'ja')?.name,
        description: ecDetail.lead_message,
        image: [ecDetail.product_image_2, ecDetail.product_image_1, ecDetail.product_image_3].filter(nonNullable),
        // レビューが 1件 以上ある場合のみに設定するようにする
        aggregateRating:
          ecDetail.review_settings &&
          (ecDetail.review_settings.review_total > 0
            ? {
                '@type': 'AggregateRating',
                ratingValue: ecDetail.review_settings.rating_average.toString(),
                bestRating: '5',
                reviewCount: ecDetail.review_settings.review_total || undefined,
              }
            : undefined),
        review: comments
          ? comments.map(({ rating, nickname, body }) => ({
              '@type': 'Review',
              reviewRating: rating
                ? {
                    '@type': 'Rating',
                    ratingValue: rating.toString(),
                  }
                : undefined,
              author: nickname
                ? {
                    '@type': 'Person',
                    name: nickname,
                  }
                : undefined,
              reviewBody: body,
            }))
          : undefined,
        offers: {
          '@type': 'Offer',
          url: resolvePageUrl(null),
          priceCurrency: 'JPY',
          price: specialPrice ?? bestPricedVariant?.price_settings.price_including_tax,
          availability: getProductAvailability(ecDetail),
          hasMerchantReturnPolicy: {
            '@type': 'MerchantReturnPolicy',
            applicableCountry: 'JP',
            returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
            merchantReturnDays: 7,
          },
          shippingDetails: {
            '@type': 'OfferShippingDetails',
            shippingRate: {
              '@type': 'MonetaryAmount',
              value: isFreeShipping ? 0 : DEFAULT_POSTAGE,
              currency: 'JPY',
            },
          },
        },
        brand: {
          '@type': 'Brand',
          name: master_settings.producer?.name_kana,
        },
        sku: code,
      },
    };
  };

  const getHowtoSchema = ({ type, data }: JsonLdHowTo): JsonLdSchema<HowTo> => {
    return {
      type,
      schema: {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: data.title,
        description: data.description,
        image: data.image ?? {
          '@type': 'ImageObject',
          url: resolvePageUrl(data.image),
        },
        step: data.steps.map(({ title, image, items }) => ({
          '@type': 'HowToStep',
          url: resolvePageUrl(null),
          name: title,
          image: image ?? {
            '@type': 'ImageObject',
            url: image,
          },
          itemListElement: items.map(({ type, text }) => ({
            '@type': type === 'direction' ? 'HowToDirection' : 'HowToTip',
            text,
          })),
        })),
      },
    };
  };

  const getFAQPageSchema = ({ type, data }: JsonLdFAQPage): JsonLdSchema<FAQPage> => ({
    type,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: data.questions.slice(0, 10).map(({ title, answer }) => ({
        '@type': 'Question',
        name: title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer,
        },
      })),
    },
  });

  const getJsonLdSchema = (data: JsonLdData[], breadcrumbs: BreadcrumbsItem[]): JsonLdSchema<Thing>[] => {
    const schemas: Array<JsonLdSchema<Thing> | undefined> = data.map((item) => {
      switch (item.type) {
        case 'BlogPosting':
          return getBlogPostingSchema(item);
        case 'SearchAction':
          return getSearchActionSchema(item);
        case 'Product':
          return getProductSchema(item);
        case 'HowTo':
          return getHowtoSchema(item);
        case 'FAQPage':
          return getFAQPageSchema(item);
        default:
          throw getNeverError(item);
      }
    });

    if (breadcrumbs.length > 0) {
      schemas.push(getBreadcrumbListSchema(breadcrumbs));
    }

    return schemas.filter(nonNullable);
  };

  return {
    getJsonLdSchema,
  };
};
