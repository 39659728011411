









































































































































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import AppImage from '~/components/partials/App/AppImage.vue';
import AppLink from '~/components/partials/App/AppLink.vue';
import {
  PRODUCT_CATEGORY_ID,
  BASIC_INFO_MENU_SP,
  OTHER_ITEM_MENU_SP,
  COLOR_MENU,
  GRAPE_MENU,
  AREA_MENU,
} from '~/constants';
import { url, convertRouteToUrl } from '~/utils';
import { sendNavigationEvent } from '~/utils/ga';
import { BooleanLike } from '~/enums';

export default defineComponent({
  components: {
    AppIcon,
    AppImage,
    AppLink,
  },
  setup(props, { emit }) {
    const isColorMenuOpen = ref(true); // デフォルトは開く状態
    const isGrapeMenuOpen = ref(false);
    const isAreaMenuOpen = ref(false);

    const clickLink = (
      to:
        | string
        | {
            path: string;
            query?: Record<string, string | Array<string> | undefined>;
          },
      label: string
    ) => {
      sendNavigationEvent('searchTop', true, convertRouteToUrl(to), label);
      emit('clickLink');
    };

    const WINE_SET_LINK = {
      textKey: 'Common.sp.searchTop.category.wineSet',
      link: {
        path: url('ITEM_LIST'),
        query: { _ctg: PRODUCT_CATEGORY_ID.WINE_SET, _qty: BooleanLike.TRUE },
      },
      icon: 'SearchWineSetIcon',
    };

    return {
      url,
      PRODUCT_CATEGORY_ID,
      BASIC_INFO_MENU_SP,
      OTHER_ITEM_MENU_SP,
      COLOR_MENU,
      GRAPE_MENU,
      AREA_MENU,
      BooleanLike,
      isColorMenuOpen,
      isGrapeMenuOpen,
      isAreaMenuOpen,
      clickLink,
      WINE_SET_LINK,
    };
  },
});
