




























































































import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
  PropType,
  computed,
} from '@nuxtjs/composition-api';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import { useEnotecaApp } from '~/composables/useEnotecaApp';

export default defineComponent({
  components: {
    AppIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isLargeTitle: {
      type: Boolean,
      default: false,
    },
    contentsStyle: {
      type: String,
      default: '',
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
    noHeaderBorder: {
      type: Boolean,
      default: false,
    },
    noFooterBorder: {
      type: Boolean,
      default: false,
    },
    /** trueの場合：h-full md:h-auto */
    isFullHeightSpOnly: {
      type: Boolean,
      default: false,
    },
    /** 表示アニメーションの種類 */
    animate: {
      type: String as PropType<'fade' | 'slide-up' | 'slide-left'>,
      default: 'fade',
    },
    /** VuePortalによるテレポートを使用しないか */
    noTeleport: {
      type: Boolean,
      default: false,
    },
    /** 2層目より上のモーダルかどうか */
    isMultipleModal: {
      type: Boolean,
      default: false,
    },
    /** 大きさをコンテンツに合わせるかどうか */
    isFitContent: {
      type: Boolean,
      default: false,
    },
    /** 注記文言の文言パス */
    noteTextPath: {
      type: String,
      default: '',
    },
    isBarcodeContent: {
      type: Boolean,
      default: false,
    },
    isEventList: {
      type: Boolean,
      default: false,
    },
    /** ワインスタイル説明のモーダル */
    isWineStyle: {
      type: Boolean,
      default: false,
    },
    bodyStyle: {
      type: String,
      default: '',
    },
    /** PC用の商品詳細絞り込みモーダル：画面右配置 */
    isProductFilterModalPc: {
      type: Boolean,
      default: false,
    },
    isLargeOrderConfirm: {
      type: Boolean,
      default: false,
    },
    /** カートインモーダル：max-h-[600px]にする */
    isCartInModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, emit }) {
    // モーダルコンテンツの参照
    const contents = ref<HTMLDivElement>();
    const hasFooterSlot = slots.footer;
    const { isEnotecaAppWebView } = useEnotecaApp();

    // モーダルを起動した要素
    let triggerElement: Element | null = null;

    // 最初のタブストップ
    let firstTabStop: HTMLElement;

    // 最後のタブストップ
    let lastTabStop: HTMLElement;

    // スクロールY
    let scrollY: number;

    const modalTransitionName = computed(() => {
      switch (props.animate) {
        case 'slide-up':
          return 'translate-y';
        case 'slide-left':
          return 'translate-x';
        default:
          return 'fade';
      }
    });

    onBeforeMount(() => {
      triggerElement = document.activeElement;
    });

    onMounted(() => {
      if (props.isMultipleModal || props.isBarcodeContent) return;

      // fix body element
      scrollY = window.scrollY;
      document.body.classList.add('fixed', 'w-full');
      document.body.style.top = `${document.body.offsetTop - scrollY}px`;

      document.addEventListener('keydown', onKeydown);
    });

    onUnmounted(() => {
      if (props.isMultipleModal) return;

      // unfix body element
      document.body.classList.remove('fixed', 'w-full');
      document.body.style.top = '';
      window.scrollTo(0, scrollY - document.body.offsetTop);

      document.removeEventListener('keydown', onKeydown);

      if (triggerElement instanceof HTMLElement) {
        triggerElement.focus();
      }
    });

    // キーダウンした時
    const onKeydown = (e: KeyboardEvent) => {
      if (e.code === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstTabStop) {
            e.preventDefault();
            lastTabStop.focus();
          }
        } else if (document.activeElement === lastTabStop) {
          e.preventDefault();
          firstTabStop.focus();
        }
      }

      if (e.code === 'Escape') {
        e.stopPropagation();
        closeModal();
      }
    };

    // モーダルを閉じる
    const closeModal = () => {
      emit('close');
    };

    watch(contents, () => {
      if (!contents.value) {
        return;
      }
      const focusableNodes = contents.value.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
      );
      const focusableElements = Array.prototype.slice.call(focusableNodes);

      firstTabStop = focusableElements[0];
      lastTabStop = focusableElements[focusableElements.length - 1];

      firstTabStop.focus();
    });

    return {
      contents,
      modalTransitionName,
      closeModal,
      hasFooterSlot,
      isEnotecaAppWebView,
    };
  },
});
