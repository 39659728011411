















































import { computed, defineComponent, useContext, watch, ref } from '@nuxtjs/composition-api';
import { castArray } from 'lodash-es';
import CancelButton from '~/components/partials/App/AppCancelButton.vue';
import AppIcon from '~/components/partials/App/AppIcon.vue';
import SubmitButton from '~/components/partials/App/AppSubmitButton.vue';
import { useAlert } from '~/composables/useAlert';
import { useMatchMedia } from '~/composables/useMatchMedia';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { nonNullable } from '~/utils';
import { AlertButton } from '~/types/ui';

export default defineComponent({
  components: {
    AppIcon,
    SubmitButton,
    CancelButton,
  },
  setup() {
    const { i18n } = useContext();
    const { activeAlert, closeAlert } = useAlert();
    const { isDesktop } = useMatchMedia();
    const { DEBUG_MODE } = useRuntimeConfig();
    const isDisableButton = ref(false);
    const messages = computed(() => castArray(activeAlert.value.message).filter(nonNullable));
    const debugMessages = computed(() =>
      DEBUG_MODE === 'enabled' ? castArray(activeAlert.value.debug).filter(nonNullable) : []
    );
    const buttons = computed(() => {
      const fallback: AlertButton[] = [{ text: i18n.t('Common.button.close').toString(), style: 'submit' }];

      return activeAlert.value.buttons || fallback;
    });

    // 押下後に無効化するか
    const isDisabled = computed(() => {
      return activeAlert.value.protectDoubleClick;
    });

    // アクティブなアラートを閉じる
    const closeActiveAlert = async () => {
      if (!activeAlert.value) {
        return;
      }

      const { id, onClose } = activeAlert.value;

      // アラート自体のハンドラを実行
      if (onClose && (await Promise.resolve(onClose())) === false) {
        return;
      }

      closeAlert(id);
      isDisableButton.value = false;
    };

    // ボタンがクリックされた際の処理
    const onButtonClick = async (button: AlertButton) => {
      if (!activeAlert.value) {
        return;
      }

      // 無効化するフラグがtrueの場合、多重クリック防止のため無効化
      if (isDisabled.value) {
        isDisableButton.value = true;
      }

      const { onClick } = button;

      // ボタンのハンドラを実行
      if (onClick && (await Promise.resolve(onClick())) === false) {
        return;
      }

      await closeActiveAlert();
    };

    // strictClose が true でない場合のみアラートを閉じる
    const tryWeakClose = async () => {
      if (activeAlert.value?.strictClose) {
        return;
      }

      await closeActiveAlert();
    };

    const onCloseIconClick = async () => {
      const cancelButton = buttons.value.find((button) => button.style === 'cancel');

      if (!cancelButton) {
        await closeActiveAlert();
        return;
      }

      await onButtonClick(cancelButton);
    };

    // Keydownイベントリスナ
    const onKeydown = async (e: KeyboardEvent) => {
      // Escapeキーが押された際に
      if (e.key === 'Escape') {
        await tryWeakClose();
      }
    };

    // アラートが表示されているときに keydown イベントを仕込む
    watch(activeAlert, (current, old, invalidate) => {
      window.addEventListener('keydown', onKeydown);

      invalidate(() => {
        window.removeEventListener('keydown', onKeydown);
      });
    });

    // SPで縦並び表示になるか否か
    const isSpVertical = computed(() => {
      return activeAlert.value.spVertical && !isDesktop.value;
    });

    return {
      activeAlert,
      messages,
      debugMessages,
      buttons,
      isSpVertical,
      isDisableButton,
      onButtonClick,
      tryWeakClose,
      onCloseIconClick,
    };
  },
});
