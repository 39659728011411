

































import { computed, defineComponent } from '@nuxtjs/composition-api';
import AppModal from '~/components/partials/App/AppModal.vue';
import BasicInfoSearchMenu from '~/components/partials/Search/BasicInfoSearchMenu.vue';
import SearchInputWithSuggestion from '~/components/partials/Search/SearchInputWithSuggestion.vue';
import SimpleSearchMenu from '~/components/partials/Search/SimpleSearchMenu.vue';
import { useStore } from '~/composables/useStore';
import { sendNavigationEvent } from '~/utils/ga';

export default defineComponent({
  components: {
    AppModal,
    SearchInputWithSuggestion,
    BasicInfoSearchMenu,
    SimpleSearchMenu,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const isBasicInfo = computed(() => store.state.modules.general.isBasicInfoTab);

    const onClickBasicInfo = () => {
      if (!isBasicInfo.value) {
        sendNavigationEvent('searchTop', true, undefined, '基本情報から検索');
        store.commit('modules/general/setIsBasicInfoTab', true);
      }
    };
    const onClickSimpleSearch = () => {
      if (isBasicInfo.value) {
        sendNavigationEvent('searchTop', true, undefined, 'かんたん検索');
        store.commit('modules/general/setIsBasicInfoTab', false);
      }
    };

    const close = () => {
      emit('close');
    };

    return {
      isBasicInfo,
      onClickBasicInfo,
      onClickSimpleSearch,
      close,
    };
  },
});
