var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.noTeleport ? 'div' : 'Portal',{tag:"Component",attrs:{"to":_vm.noTeleport ? undefined : 'modal'}},[_c('Transition',{attrs:{"appear":"","name":"fade"}},[(!_vm.noBackground)?_c('div',{staticClass:"fixed left-0 top-0 z-80 flex h-full w-full items-end justify-center bg-Black60 md:items-center md:py-0"}):_vm._e()]),_vm._v(" "),_c('Transition',{attrs:{"appear":"","name":_vm.modalTransitionName}},[_c('div',{staticClass:"fixed bottom-0 left-0 top-0 z-80 flex w-full items-end justify-center pt-60px md:m-auto md:pb-60px",class:{
        'border-b md:items-center': !_vm.isBarcodeContent,
        'items-center': _vm.isBarcodeContent,
        'bottom-28': _vm.isEventList && _vm.isEnotecaAppWebView,
      },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal($event)},"keydown":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal($event)}}},[_c('div',{ref:"contents",staticClass:"flex max-h-full w-full flex-col rounded-t bg-White md:rounded",class:{
          'h-auto': _vm.isFitContent,
          'h-full': !_vm.isFitContent,
          'md:h-auto': _vm.isFullHeightSpOnly,
          'mx-8': _vm.isBarcodeContent,
          'md:max-h-[560px]': !_vm.isWineStyle && !_vm.isProductFilterModalPc && !_vm.isLargeOrderConfirm && !_vm.isCartInModal,
          'md:max-h-[600px]': _vm.isLargeOrderConfirm,
          'md:max-h-[680px]': _vm.isCartInModal,
          'md:max-w-2xl': !_vm.isWineStyle,
          'w-824px': _vm.isWineStyle,
          'fixed right-0 top-0': _vm.isProductFilterModalPc,
        },style:(_vm.contentsStyle),attrs:{"tabindex":"0"}},[_c('div',{staticClass:"relative flex items-center justify-between",class:{
            'border-b': !_vm.noHeaderBorder,
            'p-4 md:items-start': !_vm.isProductFilterModalPc,
            'p-7': _vm.isProductFilterModalPc,
          }},[_c('div',[_vm._t("header",[_c('h3',{staticClass:"font-bold",class:{
                  'md:mx-10 md:mt-6': !_vm.isProductFilterModalPc,
                  'text-lg': !_vm.isLargeTitle,
                  'text-2xl': _vm.isLargeTitle,
                }},[_vm._v("\n                "+_vm._s(_vm.title)+"\n              ")]),_vm._v(" "),(_vm.noteTextPath)?_c('i18n',{staticClass:"mt-2 text-sm text-Black80 md:mx-10",attrs:{"tag":"p","path":_vm.noteTextPath}},[_c('br')]):_vm._e()])],2),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('button',{on:{"click":_vm.closeModal}},[_c('AppIcon',{staticClass:"h-6 w-6",attrs:{"icon":"action-close"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"flex-grow overflow-y-auto",class:_vm.isProductFilterModalPc ? 'p-7' : 'p-4 md:px-60px',style:(_vm.bodyStyle)},[_vm._t("body")],2),_vm._v(" "),(_vm.hasFooterSlot)?_c('div',{staticClass:"flex-none",class:{
            'border-t': !_vm.noFooterBorder,
            'p-7': _vm.isProductFilterModalPc,
            'px-4 pb-10 pt-15px md:border-t-0 md:px-60px md:pt-0': !_vm.isProductFilterModalPc,
          }},[_vm._t("footer")],2):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }