/**
 * UAがエノテカアプリか
 */
export const isEnotecaApp = (userAgent?: string): boolean => !!userAgent?.includes('ENOTECA');

/**
 * アプリのバージョン2.15.*以上かどうか
 *
 * アプリのレビュー依頼ダイアログを表示するかどうかの判定に使用
 * @see https://team-lab.backlog.com/view/ENOTECA_ALL-14105
 */
export const isEnotecaAppVersionHigherThanReview = (userAgent?: string): boolean => {
  const appVersion = userAgent?.split('ENOTECA/')[1];
  if (!appVersion) {
    return false;
  }

  const versions = appVersion.split('.').map(Number);
  if (versions[0] >= 2 && versions[1] >= 15) {
    return true;
  }

  return false;
};
